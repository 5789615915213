.app {
  display: flex;
  width: 100%;
}

aside {
  min-width: fit-content;
}

aside > nav > ul {
  list-style-type: none;
  padding: 50px 20px;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

aside > nav > ul > li > a {
  color: teal;
  text-decoration: none;
  font-weight: bold;
}

main {
  flex: 1;
  overflow: scroll;
}